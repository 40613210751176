import React from "react";
import "../../assets/styling/surveypop.css";

const SurveyPop = ({ show, onHide }) => {
  if (!show) return null;

  return (
    <div className="survey-pop-overlay">
      <div className="survey-pop-container">
        <div className="survey-pop-content">
          <div className="survey-pop-icon">
            <img src={"/images/center.png"} alt="logo" />
          </div>
          <h1 className="survey-pop-title">Let's Tailor Your Business Brief</h1>
          <p className="survey-pop-description">
            Help us fine tune your business brief with a{" "}
            <strong>45-second survey</strong> about your skills and strengths.{" "}
          </p>
          <button className="survey-pop-button" onClick={onHide}>
            Let's Go
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyPop;
