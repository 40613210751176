import React, { useState, useEffect } from "react";
import "../assets/styling/footer.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";

const CookieConsent = () => {
  const [consent, setConsent] = useState(false);
  const giveConsent = () => {
    Cookies.set("cookieConsent", true);
    setConsent(true);
  };

  useEffect(() => {
    const consentValue = Cookies.get("cookieConsent");
    // consentValue ? setConsent(true) : setConsent(false);
    setConsent(true);
  }, []);
  const cookieBanner = (
    <div className="cookie-banner">
      <p>
        We employ cookies to enhance your experience on our website. By
        continuing to use this site, we assume you are comfortable with this
        practice. To discover more about how we utilize cookies, please visit{" "}
        <Link to="/privacy">here.</Link>
      </p>
      <Button id="cookie-btn" onClick={giveConsent}>
        Ok
      </Button>
      <div id="close-consent" onClick={giveConsent}>
        <CloseIcon />
      </div>
    </div>
  );

  return !consent ? cookieBanner : null;
};

export default CookieConsent;
