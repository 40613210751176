// src/components/Question.js
import React, { useState, useEffect } from "react";
import {
  Slider,
  Button,
  Box,
  Fade,
  Stack,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import ProgressBar from "./BizProgressBar";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { mbti_list } from "../../utils/mbti_list";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#333",
    },
    success: {
      main: "#5381f1",
    },
  },
});
const valuetext = (value) => {
  return `${value}`;
};

const BizQuestion = ({
  question,
  answers,
  onNext,
  onPrevious,
  handleSelection,
  currentAnswer,
  currentQuestion,
  lastQ,
  current,
  total,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    currentAnswer >= 0 ? currentAnswer : 0
  );
  // const [dropDown, setDropdown] = useState(false);
  // const [selectedMBTI, setMBTI] = useState("");
  const [image, setImage] = useState(question.images[0]);
  const [sub, setSub] = useState(question.subtext[selectedOption]);
  const fallbackUrl = "/images/aboutusoffice.jpg";

  useEffect(() => {
    setSelectedOption(currentAnswer >= 0 ? currentAnswer : 0);
  }, [currentAnswer]);

  useEffect(() => {
    // const selectedAnswer = answers[question.text];
    // setSelectedOption(
    //   selectedAnswer !== undefined && !mbti_list.includes(selectedAnswer)
    //     ? question.options.indexOf(selectedAnswer)
    //     : 0
    // );
    const selectedAnswer = answers[question.text];
    let index;
    if (typeof selectedAnswer === "number") {
      index = selectedAnswer;
    } else {
      index = question.options.indexOf(selectedAnswer);
    }
    setSelectedOption(
      index >= 0 && index < question.options.length ? index : 0
    );
  }, [question]);

  useEffect(() => {
    setImage(question.images[0]);
    if (selectedOption >= 0 && selectedOption < question.subtext.length) {
      setSub(question.subtext[selectedOption]);
    } else {
      setSub("");
    }
  }, [selectedOption, question.images, question.subtext]);

  const marks = question.options.map((option, index) => ({
    value: index,
    label: option,
  }));

  // const handleMBTI = (choice) => {
  //   setMBTI(choice);
  //
  //   handleSelection(choice, currentQuestion);
  // };

  const handleChoice = (newValue, move = false, next_spot = true) => {
    let answer;

    // if (dropDown && lastQ) {
    //   // MBTI selection is active
    //   if (selectedMBTI !== "") {
    //     answer = selectedMBTI;
    //     handleSelection(answer, currentQuestion);
    //   } else {
    //     // No MBTI selected, default to "Introverted"
    //     answer = 0;
    //     handleSelection(answer, currentQuestion);
    //   }
    // } else
    if (newValue >= 0 && newValue < question.options.length) {
      setSelectedOption(newValue);
      answer = question.options[newValue];
      handleSelection(answer, currentQuestion);
    }

    if (move) {
      if (next_spot) {
        onNext();
      } else {
        onPrevious();
      }
    }
  };

  // const useSimple = () => {
  //   setDropdown(false);
  //   setMBTI("");
  //   setSelectedOption(0);
  //   handleSelection(question.options[0], currentQuestion);
  //   setImage(question.images[0]);
  //   setSub(question.subtext[0]);
  // };

  // const mbti = () => {
  //   return (
  //     <div className="mbti">
  //       For better results enter Myers Briggs{" "}
  //       <span
  //         onClick={() => {
  //           setDropdown(true);
  //         }}
  //       >
  //         here
  //       </span>
  //     </div>
  //   );
  // };

  return (
    <Fade in={true}>
      <div
        className="survey-box"
        // style={{ backgroundImage: "url(" + image + ")" }}
        mt={5}
      >
        <div className="question-box">
          <div style={{ margin: "0 auto 10px", width: "90%" }}>
            <ProgressBar current={current} total={total} />
          </div>
          <div
            style={{
              height: "250px",
              width: "90%",
              margin: "auto",
              // backgroundImage: "url(" + image + ")",
              // backgroundSize: "cover",
              // backgroundPosition: "center center",
              // backgroundRepeat: "no-repeat",
            }}
          >
            <img
              src={image || fallbackUrl}
              alt="Question"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop if fallback also fails
                e.target.src = fallbackUrl;
              }}
            />
          </div>

          <Box
            className="lower-box"
            style={{ padding: "0 32px 1rem", margin: "0px auto 0" }}
          >
            <div className="customize-question">{question.text}</div>
            {/* {dropDown && lastQ ? (
              <div className="mbti-autocomplete">
                <Stack spacing={2} style={{ width: "100%" }}>
                  <Autocomplete
                    freeSolo
                    options={mbti_list.map((option) => option)}
                    value={selectedMBTI}
                    onChange={(event, newValue) => handleMBTI(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input"
                        label="Myers Briggs"
                        variant="filled"
                      />
                    )}
                  />
                </Stack>
                <p
                  className="simple-version"
                  onClick={() => {
                    useSimple();
                  }}
                >
                  Use the simple version
                </p>
              </div>
            ) : ( */}
            <>
              <Slider
                aria-label=""
                value={selectedOption}
                getAriaValueText={valuetext}
                step={1}
                style={{
                  height: "15px",
                  color: question.color.a,
                  width: "80%",
                  margin: "1.2rem 0 0.6rem",
                }}
                marks={marks.map((mark) => ({
                  ...mark,
                  label: (
                    <Tooltip title={mark.label} placement="top">
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                          maxWidth: "80px",
                        }}
                      >
                        {mark.label}
                      </span>
                    </Tooltip>
                  ),
                }))}
                max={question.options.length - 1}
                onChange={(event, newValue) => handleChoice(newValue)}
              />
              {/* {lastQ ? mbti() : null} */}
              <div
                className="customize-subtext"
                style={{
                  background: question.color.b + "3b",
                  margin: "5px 0 0",
                }}
              >
                <b>{`${question.options[selectedOption]}`}: </b>
                {`${sub}`}
              </div>
            </>
            {/* )} */}
          </Box>
        </div>

        <ThemeProvider theme={theme}>
          {" "}
          <Box
            className="bottom-box"
            display="flex"
            justifyContent="space-between"
          >
            <Button
              style={{ fontFamily: "Open Sans", textTransform: "math-auto" }}
              color="primary"
              onClick={() => handleChoice(selectedOption, true, false)}
              disabled={currentQuestion === 0}
              startIcon={<ArrowBackIosNewRoundedIcon />}
            >
              Previous
            </Button>
            <Button
              style={{ fontFamily: "Open Sans", textTransform: "math-auto" }}
              color={lastQ ? "success" : "primary"}
              onClick={() => handleChoice(selectedOption, true, true)}
              endIcon={
                lastQ ? (
                  <CheckCircleRoundedIcon />
                ) : (
                  <ArrowForwardIosRoundedIcon />
                )
              }
              variant={lastQ ? "outlined" : "text"}
            >
              {lastQ ? "Submit" : "Next"}
            </Button>
          </Box>
        </ThemeProvider>
      </div>
    </Fade>
  );
};

export default BizQuestion;
