import React, { useState, useEffect, useRef } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import "../assets/styling/subbed.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HelpIcon from "@mui/icons-material/Help";
import { color_scheme } from "../utils/helpful";
import { Link } from "react-router-dom";
const SubbedScreen = () => {
  const thank_you_msg =
    "Congratulations on signing up for The Boss Brief! We're thrilled to have you on board and can't wait to help you discover the online business that's perfectly aligned with your strengths and goals.";
  const thank_you_header = "Welcome to The Boss Brief!";
  const edition = {
    type: "Free Edition",
    delivery:
      "You can expect your customized brief on the first week of every month. Usually on Monday. For weekly reports you can",
  };
  const signoff =
    "We can't wait to help you discover and launch your perfect business venture!";
  const bullet_style = {
    marginRight: "5px",
    fontSize: 18,
    color: "#5bc699",
  };
  const [showProPanel, setShowProPanel] = useState(false);
  return (
    <div className="ty-box">
      {/* Rounded Box */}

      <div>
        <div className="confirm-title">{thank_you_header}</div>
        <div className="free-to-pro-container">
          <span
            className="ty-edition free-to-pro-trigger"
            onClick={() => setShowProPanel(!showProPanel)}
          >
            Free Edition
          </span>
          {showProPanel && (
            <div className="free-to-pro-panel">
              To upgrade to Pro go <Link to="/pro-waitlist">here</Link>
            </div>
          )}
        </div>
        <p className="confirm-msg">
          <CheckCircleIcon sx={{ fontSize: 25, color: "#5bc699" }} />
          {thank_you_msg}
        </p>
        <div>
          <img
            style={{
              width: "100%",
              height: "200px",
              objectFit: "contain",
              margin: "0rem auto",
            }}
            src="https://d2oa23hr7q6t96.cloudfront.net/playbook/fullreport_sm.gif"
          />
        </div>

        <div className="ty-detail-box">
          <div className="ty-section">
            <span>
              <FormatListBulletedIcon
                className="ty-icon"
                sx={{ fontSize: 20, color: color_scheme.lighter_version }}
              />
            </span>
            <div className="ty-title">Key Details</div>
          </div>
          <div className="ty-section-indent">
            <p style={{ marginBottom: "0.2rem" }}>
              <span
                style={{
                  borderBottom: "2px solid #707070",
                  lineHeight: "1",
                  fontSize: "1.09rem",
                }}
                className="ty-item"
              >
                Business Trend Report
              </span>
            </p>
            <p style={{ margin: "4px 0" }}>
              <span className="ty-item">Edition:</span>
              <span className="ty-detail">{edition.type}</span>
            </p>
            <p style={{ margin: "4px 0" }}>
              <span className="ty-item">Delivery:</span>
              <span className="ty-detail">
                {edition.delivery}{" "}
                <Link
                  style={{ color: "#4caf50", fontWeight: "bold" }}
                  to="/pro-waitlist"
                >
                  Upgrade to Pro!
                </Link>
              </span>
            </p>

            <p style={{ margin: "4px 0" }}>
              <span className="ty-item">Inbox Help:</span>
              <span className="ty-detail">
                Ensure our emails land in your inbox by adding{" "}
                <a
                  style={{ color: "#7c9be5", textDecoration: "none" }}
                  href="mailto:brief@thebossbrief.com"
                >
                  <b>brief@thebossbrief.com</b>
                </a>{" "}
                to your contacts.
              </span>
            </p>
          </div>

          <div className="ty-section">
            <span>
              <NextWeekIcon
                className="ty-icon"
                sx={{ fontSize: 20, color: color_scheme.lighter_version }}
              />
            </span>
            <div className="ty-title">What's Next?</div>
          </div>
          <div className="ty-section-indent">
            <div style={{ textAlign: "left" }}>
              <p>
                Dive into one of our recent reports tailored to your profile and
                see what's coming your way!
              </p>
              <div style={{ textAlign: "center" }}>
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                  src="https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/M/9cbaed52c33cf7a11d0565751ccd5b4e341c8a83dd2ae08dd576b9c17c601a13"
                />
              </div>

              <p>
                In our latest edition, we explored the booming market for{" "}
                <Link
                  style={{
                    color: "#7c9be5",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  to="/personal-business-report"
                >
                  Remineralizing Gum
                </Link>
                , revealing:
              </p>
              <ul>
                <li>
                  <CheckCircleIcon sx={bullet_style} />
                  <span>A first mover generating $2M/month</span>
                </li>
                <li>
                  <CheckCircleIcon sx={bullet_style} />
                  <span>
                    How your skills can give you a competitive edge to make
                    profit
                  </span>
                </li>
                <li>
                  <CheckCircleIcon sx={bullet_style} />
                  <span>
                    Business ideas with ready-to-implement plans you can launch
                    this weekend
                  </span>
                </li>
              </ul>
              <p>
                <Link
                  style={{ color: "#7c9be5", textDecoration: "none" }}
                  to="/personal-business-report"
                >
                  See the full report here!
                </Link>
              </p>
            </div>
          </div>

          <div className="ty-section">
            <span>
              <HelpIcon
                className="ty-icon"
                sx={{ fontSize: 20, color: color_scheme.lighter_version }}
              />
            </span>
            <div className="ty-title">Have Questions?</div>
          </div>
          <div className="ty-section-indent">
            <p>
              We're here to help! If you encounter any issues, feel free to
              reach out to us at{" "}
              <a href="mailto:support@thebossbrief.com">
                support@thebossbrief.com
              </a>
              . We'll be happy to assist you. You can also explore our{" "}
              <Link to="/faq">FAQ page</Link> for more information about
              reports.
            </p>
          </div>

          <p style={{ marginTop: "2rem" }}>{signoff}</p>
          <div style={{ textAlign: "left" }}>
            <p style={{ margin: "2rem 0 0" }}>
              <img style={{ height: "33px" }} src="/rchair.ico" />
            </p>
            <p
              style={{
                display: "inline-block",
                borderTop: "2px solid #657897",
                width: "auto",
              }}
            >
              The Boss Brief Team{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubbedScreen;
