import React, { useState, useEffect, useRef } from "react";
import { combo } from "../../utils/home_rotation";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { setPreloadedImage } from "../../utils/helpful";
import "../../assets/styling/customize.css";
import { Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useSignupMutation } from "../../slices/usersApiSlice";
import Loader from "../Loader";
import ReCAPTCHA from "react-google-recaptcha";

const QuizHomeHero = ({ version, campaign }) => {
  const [page_content, setPage] = useState(combo["v1"]);
  const [campaign_img, setCampaign] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [err, setErr] = useState(false);
  const [signup, { isLoading }] = useSignupMutation();
  const recaptchaRef = useRef(null);

  useEffect(() => {}, []);

  const startQuiz = () => {
    // setPreloadedImage(
    //   "https://d2oa23hr7q6t96.cloudfront.net/playbook/tbbdevices.gif"
    // );
    navigate("/find-your-business-fit?start=1");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") return;

    try {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      const res = await signup({ email, captchaToken }).unwrap();

      // Save access token to localStorage if signup successful
      if (res.access_token) {
        localStorage.setItem("accessToken", res.access_token);
      }

      fbq("track", "Subscribe");

      //sparkloop to handle redirect
      navigate("/find-your-business-fit?start=1");
    } catch (error) {
      console.log("error");
      setErr(true);
    }
  };

  const err_msg = (
    <span className="err-msg">Error... please try again later</span>
  );

  const bolding = (entry, type) => {
    const compiled = [];
    entry.split("[[").forEach((segment, index) => {
      if (segment.includes("]]")) {
        const parts = segment.split("]]");
        if (type === "bold") {
          compiled.push(<b key={index}>{parts[0]}</b>);
          compiled.push(parts[1]);
        }
      } else {
        compiled.push(segment);
      }
    });
    return compiled;
  };

  return (
    <div>
      <div style={{ marginTop: "4em" }} className="leader-guide-hero">
        <div className="quiz-home-hero" style={{ textAlign: "center" }}>
          <div>
            <p className="hero-title">
              {page_content.bold_title.title}
              <span style={{ textDecoration: "underline 8px #d49960" }}>
                {page_content.bold_title.span}
              </span>
              {page_content.bold_title.title2}
            </p>

            <p className="quiz-home-subhead">
              <span style={{ display: "inline-block" }}>
                {bolding(page_content.value_blurb, "bold")}
              </span>
            </p>
            <p className="quiz-home-subhead" style={{ marginBottom: "0" }}>
              <span className="join-free-text">Join for free!</span>
            </p>
            {isLoading ? (
              <Loader h={"12em"} m={"0"} w={"300px"} />
            ) : (
              <form onSubmit={handleSubmit}>
                <div>
                  {err ? err_msg : null}
                  <TextField
                    className="hero-input"
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="filled"
                    id="filled-basic"
                    label="Your Email Address"
                  />
                  <Button className="hero-sub" type="submit">
                    {page_content.heroAction} <ArrowForwardIcon />
                  </Button>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={"6LcUkYkoAAAAADH7KJoPEM1bYaEqkipI8wBlPCLU"}
                    size="invisible"
                  />
                </div>
              </form>
            )}

            {/* <div className="quiz-home-link" onClick={startQuiz}>
              {page_content.heroAction} <ArrowForwardIcon />
            </div> */}
          </div>
        </div>
        <div className="hero-right-ai">
          <div>
            {/* <img className="hero-img" src={page_content.hero_img} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizHomeHero;
