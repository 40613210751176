import React, { useState } from "react";
import FaqItem from "./FaqItem";

const quiz_faq = [
  {
    question: "What exactly will I receive?",
    answer:
      "If you're on our Free plan, you'll receive a personalized business trend report once a month. Pro subscribers get these trend reports every week, complete with in-depth research, actionable launch plans, and tailored guidance based on your unique skill set.",
  },
  {
    question: "How does the personalization work?",
    answer:
      "Based on your initial skills assessment, we match emerging business trends with your specific strengths and experience. We focus on opportunities where you have the highest chance of success. Pro or Free, each report aligns with your capabilities—but Pro subscribers receive more frequent, up-to-date opportunities.",
  },
  {
    question: "Can I cancel my Pro subscription?",
    answer:
      "Absolutely! You can cancel Pro at any time. If you're not satisfied in the first 7 days, we offer a full refund. After that, you can still cancel whenever you like—no contracts, no hassle.",
  },
  {
    question: "How practical are the business opportunities you suggest?",
    answer:
      "Every opportunity is vetted for feasibility and real profit potential. We only feature trends that everyday entrepreneurs can realistically pursue. Each report includes detailed action steps that leverage your existing skills and resources.",
  },
  {
    question: "How often will I receive reports?",
    answer:
      "Pro members get weekly reports. Free subscribers receive a monthly report. In both cases, we'll send your report directly to your inbox, packed with the latest market trends and personalized strategies.",
  },
  {
    question: "What if I need help understanding my report?",
    answer:
      "We're here to help! If something in your report isn't clear or you need deeper guidance, email us at support@thebossbrief.com.",
  },
  {
    question: "Will I need any special tools or resources?",
    answer:
      "Most of our recommended strategies can be launched with commonly available tools, many of which are free or low-cost. We'll outline any recommended resources in each report.",
  },
  {
    question: "Can I upgrade from the Free plan to Pro later?",
    answer:
      "Absolutely. You can jump into Pro whenever you're ready for weekly insights. Once you upgrade, you'll immediately start receiving weekly trend reports in your inbox.",
  },
];

const nl_faq = [
  {
    question: "When do you send the newsletter?",
    answer:
      "You can expect the newsletter at around the morning time in the EST time zone.",
  },
  {
    question: "I'm not seeing the newsletter in my inbox, what should I do?",
    answer:
      "To make sure our emails get delivered, be sure to add brief@thebossbrief.com to your contacts. Please also check your spam folders to make sure we're not in there.",
  },
  {
    question: "Where can I go to Unsubscribe?",
    answer:
      "If you ever get tired of us, feel free to hit the unsubscribe link added to the bottom of each newsletter.",
  },
  {
    question: "How can I submit feedback?",
    answer:
      "You can find a feedback option at the bottom of every newsletter. However if you want to get in contact directly feel free to reach out to Mr. Brief at: brief@thebossbrief.com",
  },
  {
    question: "Where can I find older newsletters?",
    answer: "The archive page is coming soon! Please check back later ☺️",
  },
];

const FaqSection = ({ nl }) => {
  const [openIndex, setOpenIndex] = useState(null);
  let faqUse = quiz_faq;
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const NLSection = () => {
    return (
      <div>
        <div
          style={{ textAlign: "center", marginTop: "3rem" }}
          className="section-title"
        >
          Leadership Newsletter FAQs
        </div>
        {nl_faq.map((item, index) => (
          <FaqItem
            key={index}
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    );
  };
  return (
    <div className="ai-faq">
      <div style={{ textAlign: "center" }} className="section-title">
        {nl ? "Business Report FAQs" : "Frequently Asked Questions"}
      </div>
      {faqUse.map((item, index) => (
        <FaqItem
          key={index}
          isOpen={openIndex === index}
          onClick={() => handleToggle(index)}
          question={item.question}
          answer={item.answer}
        />
      ))}
      {/* {nl ? <NLSection /> : null} */}
    </div>
  );
};

export default FaqSection;
