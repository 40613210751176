const ad_combo = {
  v1: {
    title: "Turn ",
    span: "Untapped Trends",
    title2: " into Side Income!",
    p1: "Every week, receive a report on an emerging trend📈, with proven business models and a personalized launch plan to build a profitable side business.",
    pic: "/images/snp.png",
  },
  snr: {
    title: "Turn ",
    span: "Untapped Trends",
    title2: " into Side Income!",
    p1: "Every week, receive a report on an emerging trend📈, with proven business models and a personalized launch plan to build a profitable side business.",
    pic: "/images/snr.png",
  },
  snp: {
    title: "Turn ",
    span: "Untapped Trends",
    title2: " into Side Income!",
    p1: "Every week, receive a report on an emerging trend📈, with proven business models and a personalized launch plan to build a profitable side business.",
    pic: "/images/snp.png",
  },
  snf: {
    title: "Turn ",
    span: "Untapped Trends",
    title2: " into Side Income!",
    p1: "Every week, receive a report on an emerging trend📈, with proven business models and a personalized launch plan to build a profitable side business.",
    pic: "/images/snf.png",
  },
  sns: {
    title: "Turn ",
    span: "Untapped Trends",
    title2: " into Side Income!",
    p1: "Every week, receive a report on an emerging trend📈, with proven business models and a personalized launch plan to build a profitable side business.",
    pic: "/images/sns.png",
  },
};

export const landing = (version) => {
  return ad_combo[version];
};
