import React, { useEffect } from "react";
import "../../assets/styling/surveypop.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const ProPlan = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleClick = async (e) => {
    if (localStorage.getItem("waitlistJoined")) {
      const waitlistEmail = localStorage.getItem("waitlistEmail");
      const alertOverlay = document.createElement("div");
      alertOverlay.className = "waitlist-pop-alert-overlay";

      const alertContent = document.createElement("div");
      alertContent.className = "waitlist-pop-alert-container";

      const message = document.createElement("div");
      message.className = "waitlist-pop-alert-message";
      message.innerHTML = `Don't worry you're already on the waitlist! We'll notify you at <strong>${waitlistEmail}</strong> when we open up again.`;

      const closeButton = document.createElement("button");
      closeButton.className = "waitlist-pop-alert-button";
      closeButton.textContent = "Got it";
      closeButton.onclick = () => alertOverlay.remove();

      alertContent.appendChild(message);
      alertContent.appendChild(closeButton);
      alertOverlay.appendChild(alertContent);
      document.body.appendChild(alertOverlay);

      return; // Already joined waitlist
    }

    const button = e.currentTarget;
    const ripple = document.createElement("span");
    ripple.classList.add("pro-waitlist-ripple");

    // If not in localStorage, check for access_token
    let access_token = searchParams.get("utk");
    if (!access_token) {
      console.log("used stored token");
      access_token = localStorage.getItem("accessToken");
    }
    if (access_token) {
      try {
        const response = await axios.post("/api/users/pro-waitlist", {
          access_token: access_token,
        });

        if (response && response.data && response.data.msg === "Done") {
          localStorage.setItem("waitlistJoined", "true");
          localStorage.setItem("waitlistEmail", response.data.email);
        }

        // Clear existing content and add new content with icon
        button.textContent = "You Got It! ";
        const icon = document.createElement("span");
        icon.className = "pro-waitlist-check";
        const iconElement = <CheckCircleRoundedIcon />;
        const root = createRoot(icon);
        root.render(iconElement);
        button.appendChild(icon);

        button.appendChild(ripple);
        button.classList.add("pro-waitlist-success");
        setTimeout(() => ripple.remove(), 1000);
      } catch (error) {
        console.error("Error joining waitlist:", error);
      }
    }
  };

  return (
    <div className="survey-pop-overlay">
      <div className="survey-pop-container">
        <div className="survey-pop-content">
          <div className="survey-pop-icon">
            <img
              src={
                "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/M/356ce965df953551d51cb6f2ac4935b2a4c10e14bb02727229ebf02ea0a17ef5"
              }
              alt="logo"
            />
          </div>
          <h1 className="survey-pop-title">
            Upgrade to Pro - Join the Waitlist!
          </h1>
          <p className="survey-pop-description">
            We're not accepting new Pro subscribers at the moment. Join our
            waitlist and be the first to know when we open up again!
          </p>
          <button
            className="survey-pop-button pro-waitlist-button"
            onClick={handleClick}
          >
            Notify Me
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProPlan;
