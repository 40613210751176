import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "../assets/styling/testimonials.css";

const testimonialData = [
  {
    quote:
      "I started my first side hustle after reading just three trend reports. A month later, I had my first paying client!",
    author: "Jamie L.",
    city: "New York",
    color: "#00C39A",
  },
  {
    quote:
      "These weekly insights have saved me so much time. I finally picked a niche I’m excited about, and the business plans are so helpful",
    author: "Emily S.",
    city: "Denver",
    color: "#FF7F5C",
  },
  {
    quote:
      "I've been so productive with my new company that my friends thought I hired a business coach. Thanks for pointing me in the right direction!",
    author: "Caleb T.",
    city: "New Mexico",
    color: "#FF69B4",
  },
];

const Testimonials = () => {
  return (
    <section className="testimonial-section">
      <h2 className="testimonial-title">Why Solopreneurs Love Us!</h2>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        className="testimonial-swiper"
      >
        {testimonialData.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial-card">
              <div
                className="testimonial-quote-icon"
                style={{ backgroundColor: testimonial.color }}
              >
                ❝
              </div>
              <p className="testimonial-text">{testimonial.quote}</p>
              <div className="testimonial-author">
                <h4>{testimonial.author}</h4>
                <p>{testimonial.city}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonials;
