import React from "react";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TimelineIcon from "@mui/icons-material/Timeline";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import SellRoundedIcon from "@mui/icons-material/SellRounded";
import RememberMeRoundedIcon from "@mui/icons-material/RememberMeRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import "../../../assets/styling/reportsections.css";
import RedditIcon from "@mui/icons-material/Reddit";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { report_elements } from "./report_elements";
import { bold_text } from "../../../utils/bizreport/boldtext";
const influencers = [
  {
    name: "Dr. Surina Sehgal",
    handle: "@foodiedentist",
    followers: "125K",
    description:
      "Integrates healthy eating with dental care guidance, resonating with the natural, holistic approach.",
    image:
      "https://cdn.i.haymarketmedia.asia/?n=campaign-asia%2Fcontent%2F20240405042643_Untitled+design+(6).png&h=570&w=855&q=100&v=20170226&c=1",
  },
  {
    name: "Dr. Helen Mo",
    handle: "@dentist_mom",
    followers: "98K",
    description:
      "Focuses on pediatric dental health and simplified educational content for families.",
    image:
      "https://lionize.ai/wp-content/uploads/2024/02/Who-are-the-top-health-influencers-on-Instagram.jpg",
  },
  {
    name: "Dr. Nikki",
    handle: "@_drnikki",
    followers: "156K",
    description:
      "Uses engaging content to demystify oral health topics and new dental innovations.",
    image:
      "https://www.lifeandstylemag.com/wp-content/uploads/2019/09/Best-Health-Influencers-Promo.jpg",
  },
];

const PresentDay = () => {
  const socialBadges = {
    Reddit: <RedditIcon sx={{ fontSize: 20 }} />,
    Facebook: <FacebookIcon sx={{ fontSize: 20 }} />,
    Instagram: <InstagramIcon sx={{ fontSize: 20 }} />,
  };

  return (
    <div>
      <div className="present-section">
        <div className="present-header present-search">
          <QueryStatsIcon sx={{ fontSize: 40 }} />
          <div className="present-header-text">
            <span className="present-header-subtitle">Market Intelligence</span>
            <span className="present-header-title">Search Behavior</span>
          </div>
        </div>

        <div className="present-content" style={{ background: "#8ebcfb6e" }}>
          <div className="present-stats-container">
            <div className="present-stat-card present-zoom-in">
              <div className="present-stat-number">
                {report_elements.present.search.big_num}
              </div>
              <div className="present-stat-label">
                {report_elements.present.search.subscript}
              </div>
              <div className="present-stat-change">
                {report_elements.present.search.percent}
              </div>
            </div>

            <div className="present-graph-container present-zoom-in">
              <img
                className="present-graph"
                src={report_elements.present.search.graph}
                alt="Search trend graph"
              />
            </div>
          </div>

          <div className="present-insight present-zoom-in">
            <p>{bold_text(report_elements.present.search.text)}</p>
          </div>

          <div className="present-footer">
            *Data from {report_elements.present.search.sources}
          </div>
        </div>
      </div>
      <div className="present-section">
        <div className="present-header present-social">
          <ForumRoundedIcon sx={{ fontSize: 40 }} />
          <div className="present-header-text">
            <span className="present-header-subtitle">Consumer Insights</span>
            <span className="present-header-title">Social Chatter</span>
          </div>
        </div>

        <div className="present-content" style={{ background: "#be94ee69" }}>
          <div className="present-social-grid">
            {report_elements.present.social.convos.map((convo, index) => (
              <div key={index} className="present-quote-card present-zoom-in">
                <div className="present-quote-text">{convo.quote}</div>
                <div className="present-quote-topic">{convo.label}</div>
                <div className="present-quote-source">
                  {socialBadges[convo.source]}
                  <span>{convo.source}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="present-insight present-zoom-in">
            <p>{bold_text(report_elements.present.social.text)}</p>
          </div>

          <div className="present-footer">
            *Data from {report_elements.present.social.sources}
          </div>
        </div>
      </div>

      <div className="present-section">
        <div className="present-header present-trend">
          <TimelineIcon sx={{ fontSize: 40 }} />
          <div className="present-header-text">
            <span className="present-header-subtitle">Market Analysis</span>
            <span className="present-header-title">Trend Within A Trend</span>
          </div>
        </div>

        <div className="present-content" style={{ background: "#89bdf757" }}>
          <div className="present-trend-container">
            <div className="present-wordcloud present-zoom-in">
              <img
                src={report_elements.present.meta_trend.image}
                alt="Trend word cloud"
              />
            </div>

            <div className="present-trend-stats present-zoom-in">
              <div className="present-stat-card">
                <div className="present-stat-number">Larger Trend</div>
                <div className="present-stat-label">
                  {report_elements.present.meta_trend.grouping}
                </div>
              </div>
            </div>

            <div className="present-insight present-zoom-in">
              <h4 className="present-insight-title">Analysis</h4>
              <div className="present-insight-points">
                <div className="present-insight-point">
                  <span className="present-point-title">Core Shift:</span>
                  <p>{report_elements.present.meta_trend.analysis.core}</p>
                </div>

                <div className="present-insight-point">
                  <span className="present-point-title">Key Elements:</span>
                  <ul>
                    {report_elements.present.meta_trend.analysis.keys.map(
                      (key, index) => (
                        <li key={index}>{key}</li>
                      )
                    )}
                  </ul>
                </div>

                <div className="present-insight-point">
                  <span className="present-point-title">Goal:</span>
                  <p>{report_elements.present.meta_trend.analysis.goal}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="present-footer">
            *Data from market research and trend analysis
          </div>
        </div>
      </div>

      {/* <div className="present-section">
        <div className="present-header present-influencer">
          <RememberMeRoundedIcon sx={{ fontSize: 40 }} />
          <div className="present-header-text">
            <span className="present-header-subtitle">Market Leaders</span>
            <span className="present-header-title">Influencers To Watch</span>
          </div>
        </div>

        <div className="present-content" style={{ background: "#fad7a166" }}>
          <div className="present-influencer-grid">
            {influencers.map((influencer, index) => (
              <div
                key={index}
                className="present-influencer-card present-zoom-in"
              >
                <div className="present-influencer-image">
                  <img src={influencer.image} alt={influencer.name} />
                </div>
                <div className="present-influencer-info">
                  <h3>{influencer.name}</h3>
                  <span className="present-influencer-handle">
                    {influencer.handle}
                  </span>
                  <span className="present-influencer-followers">
                    <PeopleRoundedIcon sx={{ fontSize: 16 }} />{" "}
                    {influencer.followers}
                  </span>
                  <p>{influencer.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="present-footer">
            *Data from social media analysis and industry research
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PresentDay;
