// src/components/Survey.js
import React, { useState, useEffect } from "react";
import Question from "./BizQuestion";
import { Box } from "@mui/material";
import { mbti_list } from "../../utils/mbti_list";
import { Link, useNavigate } from "react-router-dom";
import BizQuizResults from "./BizQuizResults";
import Loader from "../Loader";
import axios from "axios";
import { logClick } from "../../utils/clicktracker";
import { setPreloadedImage } from "../../utils/helpful";

const convertObjectAToB = (objectA, questions) => {
  const objectB = {};

  for (const questionId in questions) {
    const questionInfo = questions[questionId];
    const questionText = questionInfo.text;
    const key = questionInfo.key;

    if (objectA.hasOwnProperty(questionText)) {
      const answerValue = objectA[questionText];

      if (typeof answerValue === "number") {
        // For numerical indices, you can store them as-is or map to option text
        objectB[key] = answerValue; // or map to option text if needed
      } else {
        // For MBTI string, store it directly
        objectB[key] = answerValue;
      }
    }
  }

  return objectB;
};

// ge
function QuizActive({ questions, showFAQ, version, campaign }) {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState({
    topResults: [],
    mostFun: [],
    bestForSkillset: [],
  });
  const [answer_map, setMap] = useState({});
  const [num_map, setNumMap] = useState({});
  const [custom_cta, setCustomCTA] = useState({});
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Preload the next question's image when currentQuestion changes
    const nextQuestionIndex = currentQuestion + 1;
    const nextQuestion = questions[nextQuestionIndex];

    if (nextQuestion && nextQuestion.images && nextQuestion.images.length > 0) {
      const nextImageUrl = nextQuestion.images[0];
      preloadImage(nextImageUrl);
    }
  }, [currentQuestion, questions]);

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
    // No need to handle onload/onerror since we're not awaiting this
  };

  const uploadUserPref = async (answers) => {
    setLoading(true);

    //preload images
    setPreloadedImage(
      "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Dis/b998f07f63bd94a23e9de737d528e10742be349877e18a0c32ef3a8580676750"
    ); //Top
    setPreloadedImage(
      "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Dis/75dd768f56af2c0ee742049f07659f3011c4c23b4f1db465da1a784ab5d55d1a"
    ); //Fun
    setPreloadedImage(
      "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Dis/cd0fc4528a665b87278730c3c7d7367173814f793442378b3ea03f2cccaaa11e"
    ); //Skill
    try {
      // Get access token from localStorage
      let accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        accessToken = "none";
      }

      const res = await axios.post("/api/users/biz-quiz", {
        answers,
        access_token: accessToken, // Simply pass token in request body
      });

      if (res.data) {
        // setResults(res.data.results);
        // setMap(res.data.answer_map);
        setNumMap(answers);
        // setCustomCTA(res.data.cta_wording);
        // setToken(res.data.tempUserId);
        // localStorage.setItem("tempUserId", res.data.tempUserId);
        //set score in local storage or session
        localStorage.setItem("quizScore", JSON.stringify(answers));
        localStorage.setItem(
          "bizAlignment",
          JSON.stringify(res.data.survey_alignment)
        );
      }

      setLoading(false);
      showFAQ();
      await logClick(version, campaign, "finish");
      navigate("/subbed");
    } catch (err) {
      console.log("there was an error, please contact support");
      setLoading(false);
      alert(
        "An error occurred while processing your answers. Please try again."
      );
    }
  };

  const handleSelection = (answer, question) => {
    const newAnswers = { ...answers };
    let answerIndex = questions[question].options.indexOf(answer);
    if (answerIndex === -1) {
      // Answer not found in options (e.g., MBTI type)

      newAnswers[questions[question].text] = answer;
    } else {
      newAnswers[questions[question].text] = answerIndex;
    }

    setAnswers(newAnswers);
  };

  const handleNext = () => {
    const nextQuestion = currentQuestion + 1;
    setCurrentQuestion(nextQuestion);
    if (nextQuestion < Object.keys(questions).length) {
      const selectedAnswer = answers[questions[nextQuestion]?.text];

      let selectedIndex;
      if (typeof selectedAnswer === "number") {
        selectedIndex = selectedAnswer;
      } else {
        selectedIndex = questions[nextQuestion].options.indexOf(selectedAnswer);
      }
      if (selectedIndex === -1 || selectedIndex === undefined) {
        selectedIndex = 0;
      }

      setCurrentAnswer(selectedIndex);
    } else {
      //   console.log("reached end of quiz");
      // Ensure the last question's answer is set before submission
      const lastQuestionText = questions[currentQuestion].text;
      const lastQuestionAnswer = answers[lastQuestionText];
      if (lastQuestionAnswer === undefined) {
        // Default to the first option if no answer was selected
        answers[lastQuestionText] = questions[currentQuestion].options[0];
        setAnswers({ ...answers });
      }

      const toUpload = convertObjectAToB(answers, questions);

      uploadUserPref(toUpload);
    }
  };

  const handlePrevious = () => {
    const previousQuestion = currentQuestion - 1;
    setCurrentQuestion(previousQuestion);
    const selectedAnswer = answers[questions[previousQuestion]?.text];

    let selectedIndex;
    if (typeof selectedAnswer === "number") {
      selectedIndex = selectedAnswer;
    } else {
      selectedIndex =
        questions[previousQuestion].options.indexOf(selectedAnswer);
    }
    if (selectedIndex === -1 || selectedIndex === undefined) {
      selectedIndex = 0;
    }

    setCurrentAnswer(selectedIndex);
  };
  if (isLoading) {
    return (
      <div className="quiz-loader" style={{ marginBottom: "400px" }}>
        <img
          style={{
            width: "100%",
            borderRadius: "20px",
            border: "2px solid #cedaf847",
          }}
          src="https://d2oa23hr7q6t96.cloudfront.net/playbook/tbbdevices.gif"
        />
      </div>
    );
  }
  return (
    <Box>
      {currentQuestion < Object.keys(questions).length ? (
        <Question
          current={currentQuestion + 1}
          total={Object.keys(questions).length}
          question={questions[currentQuestion]}
          onNext={handleNext}
          onPrevious={handlePrevious}
          handleSelection={handleSelection}
          currentAnswer={currentAnswer}
          answers={answers}
          currentQuestion={currentQuestion} // Pass currentQuestion as a prop
          lastQ={Object.keys(questions).length - 1 === currentQuestion}
        />
      ) : (
        <div />
        // <BizQuizResults
        //   map={answer_map}
        //   results={results}
        //   version={version}
        //   campaign={campaign}
        //   num_map={num_map}
        //   custom_cta={custom_cta}
        //   token={token}
        // />
      )}
    </Box>
  );
}

export default QuizActive;
