// Big text - #6082D4
// Background light #f7f9ff
// Background dark #00347a
// Icon color - #6082D4
// Text color - black

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TakeQuizModal from "../../components/BizReport/ReportComponents/TakeQuizModal";
import "../../assets/styling/bizreport.css";
import AnimatedArrows from "../../components/BizReport/AnimatedArrows";
import Dashboard from "../../components/BizReport/ReportComponents/Dashboard";
import ReportTitle from "../../components/BizReport/ReportComponents/ReportTitle";
import Comic from "../../components/BizReport/ReportComponents/Comic";
import PresentDay from "../../components/BizReport/ReportComponents/PresentDay";
import Meaning from "../../components/BizReport/ReportComponents/Meaning";
import Legend from "../../components/BizReport/ReportComponents/Legend";
import Case from "../../components/BizReport/ReportComponents/Case";
import {
  gsap,
  ScrollTrigger,
  MotionPathPlugin,
} from "../../utils/ScrollAnimations/gsapConfig";
import withGsapAnimation from "../../utils/ScrollAnimations/withGsapAnimation";
import SwipeRevealBox from "../../components/BizReport/ReportComponents/SwipeRevealBox";
import Timeline from "../../components/BizReport/ReportComponents/Timeline";
import {
  animations,
  defaultOptions,
} from "../../utils/ScrollAnimations/animations";
import { idea_backgrounds } from "../../utils/bizreport/reportutils";
import Idea from "../../components/BizReport/ReportComponents/Idea";
import CTA from "../../components/BizReport/ReportComponents/CTA";
import { report_elements } from "../../components/BizReport/ReportComponents/report_elements";
import axios from "axios";

// const AnimatedReportTitle = withGsapAnimationSimple(ReportTitle, "bgColorChange");
const AnimatedReportIntro = withGsapAnimation(ReportTitle, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.fade, animations.underline],
  displayName: "intro",
});
const AnimatedReportTitle = withGsapAnimation(ReportTitle, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.fade, animations.underline],
  displayName: "title",
});

const AnimatedSwipeRevealBox = withGsapAnimation(SwipeRevealBox, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.boxSwipeReveal],
});
const AnimatedComic = withGsapAnimation(Comic, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.comicPanelSequence],
  displayName: "comic",
});

const AnimatedTimeline = withGsapAnimation(Timeline, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.timelineSequence],
  displayName: "timeline",
});

const AnimatedPresentDay = withGsapAnimation(PresentDay, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.presentDaySequence],
  displayName: "present",
});

const AnimatedCase = withGsapAnimation(Case, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.caseStudySequence],
  displayName: "case",
});
const AnimatedMeaning = withGsapAnimation(Meaning, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.meaningSequence],
  displayName: "meaning",
});

const AnimatedIdea = withGsapAnimation(Idea, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.ideaSequence],
  displayName: "idea_one",
});
const AnimatedIdeaTwo = withGsapAnimation(Idea, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.ideaSequence],
  displayName: "idea_two",
});

const AnimatedCTA = withGsapAnimation(CTA, {
  defaultTriggerOptions: defaultOptions,
  animations: [animations.ctaSequence],
  displayName: "cta",
});

const BizReport = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [bizAlignment, setBizAlignment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const scrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const checkBizAlignment = async () => {
      try {
        // First check localStorage
        const storedBizAlignment = localStorage.getItem("bizAlignment");

        if (storedBizAlignment) {
          const parsedAlignment = JSON.parse(storedBizAlignment);
          setBizAlignment(parsedAlignment);
          return;
        }

        // If not in localStorage, check for access_token
        const access_token = searchParams.get("utk");
        if (access_token) {
          const res = await axios.post("/api/users/biz-alignment", {
            access_token: access_token,
          });

          if (res.data && res.data.bizAlignment) {
            setBizAlignment(res.data.bizAlignment);
            localStorage.setItem(
              "bizAlignment",
              JSON.stringify(res.data.bizAlignment)
            );
            return;
          }
        }

        // If we get here, no alignment was found
        setShowPopup(true);
      } catch (error) {
        console.error("Error checking biz alignment:", error);
        setShowPopup(true);
      }
    };

    checkBizAlignment();
  }, []);

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);

    // Set loading false after a brief delay to ensure scroll position
    setTimeout(() => {
      setIsLoading(false);
      ScrollTrigger.refresh();
    }, 100);

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  useEffect(() => {
    const updateProgressBar = () => {
      const windowHeight = window.innerHeight;
      const documentHeight =
        document.documentElement.scrollHeight - windowHeight;
      const scrolled = window.scrollY;
      const progress = (scrolled / documentHeight) * 100;

      const progressBar = document.querySelector(".biz-report-progress-bar");
      if (progressBar) {
        progressBar.style.width = `${progress}%`;
      }
    };

    window.addEventListener("scroll", updateProgressBar);

    // Initial call to set initial progress
    updateProgressBar();

    return () => window.removeEventListener("scroll", updateProgressBar);
  }, []);

  if (showPopup) {
    return (
      <div>
        <div className="biz-report-all-background" />
        <TakeQuizModal show={showPopup} />
        <div className="biz-report-background">
          <h1 className="biz-report-title">
            Personalized Business Trend Report
          </h1>
          <div className="biz-report-logo" />
        </div>
      </div>
    );
  }

  return (
    <div className="biz-report-container">
      {isLoading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <>
          {/* <div className="biz-report-progress-bar" /> */}
          <div className="biz-report-all-background" />

          <div className="biz-report-background">
            <h1 className="biz-report-title">
              Personalized Business Trend Report
            </h1>
            <div className="biz-report-logo" />
          </div>
          <div className="biz-report-content">
            <div className="dashboard-summary">
              {/* A dashboard summary type section with 3 rounded rectangular cards. Each ones has an image on the left, a title, and a description on the right. */}
              <p className="report-header-text">This Free Report Includes:</p>
              <Dashboard matches={bizAlignment} />
            </div>
            <p className="scroll-to-opening-text">
              <span>Scroll down</span> to dive into the report and see{" "}
              <span>your</span> tailored ideas!
            </p>
            <div
              className="animated-arrows-container"
              onClick={() => scrollTo("opening-section")}
            >
              <AnimatedArrows />
            </div>

            <div style={{ height: "100px" }} />
            {/* Opening Section */}
            <div id="opening-section" className="opening-section">
              <AnimatedReportIntro
                title1={report_elements.open_title.small}
                title2={report_elements.open_title.large}
              />
            </div>
            <div className="biz-report-main-image">
              <img src={report_elements.open_image} alt="Main Image" />
            </div>
            <AnimatedSwipeRevealBox text="intro" />
            {/* comic */}
            <AnimatedComic />
            <AnimatedSwipeRevealBox text="history" />
            {/* History of Topic Section */}
            <AnimatedReportTitle
              title1={report_elements.history_title.small}
              title2={report_elements.history_title.large}
            />
            <AnimatedTimeline />
            {/* Present Day Section */}
            <AnimatedReportTitle
              title1={report_elements.present_title.small}
              title2={report_elements.present_title.large}
            />
            <AnimatedPresentDay />
            {/* Case Study Section */}
            <AnimatedReportTitle
              title1={report_elements.case_title.small}
              title2={report_elements.case_title.large}
            />
            <AnimatedCase />
            {/* What it means to you */}
            <AnimatedReportTitle
              title1={report_elements.meaning_title.small}
              title2={report_elements.meaning_title.large}
            />
            {bizAlignment ? (
              <AnimatedMeaning meaning={bizAlignment[0].meaning} />
            ) : null}
            {/* Potential Ideas Section */}
            {bizAlignment ? (
              bizAlignment.map((alignment, index) => (
                <div key={index}>
                  <AnimatedReportTitle
                    title1="Business Idea"
                    title2={`Number ${index + 1}`}
                  />
                  {index === 0 ? (
                    <AnimatedIdea
                      key={alignment.id}
                      matches={alignment}
                      styling={idea_backgrounds[index]}
                      index={index}
                    />
                  ) : (
                    <AnimatedIdeaTwo
                      key={alignment.id}
                      matches={alignment}
                      styling={idea_backgrounds[index]}
                      index={index}
                    />
                  )}
                </div>
              ))
            ) : (
              <p>Loading ideas...</p>
            )}
            {/* Transition to CTA */}
            <AnimatedSwipeRevealBox text="cta" />
            {/* Sign Off Section */}
            {/* <div id="launch-plan">
              <AnimatedCTA />
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default BizReport;
