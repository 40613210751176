export const report_elements = {
  open_image:
    "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/01e46075970ec0ac5fa4ad43eac5f0be38dcb359e1bc79f8dc8f84fd596bc3ae",
  open_title: { small: "The Rise of", large: "Remineralizing Gum" },
  transition_one: {
    title: "Who is driving this?",
    text: "To better understand the significance of remineralizing gum, let's [examine the experience of one type of customer:] Emily, a 27-year-old wellness advocate living in Portland, Oregon.",
  },
  comic: {
    panel_one: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/36578d5c444b2ae8c5e759910aba996fbb26a433e70140f28832c100318240f4",
      text: "Emily is a yoga instructor who is deeply committed to a holistic lifestyle, but she [struggles to find a gum that aligns with her values], supports oral health and keeps her breath fresh during close interactions with her students.",
    },
    panel_two: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/654cfd426ab510da9f5fdb67c9386470243a8a50dcca6bdad0ebcfbbc0bcb5a0",
      text: "Her frustration grows every time she chews regular gum, knowing it contains [artificial sweeteners and chemicals that clash with her health principles]. Despite her efforts—scouring health stores and experimenting with homemade gum—she's faced repeated disappointment.",
    },
    panel_three: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/676e0a16729373906952592d7c164dc9179714de87a77d1cbfe99792cc87a193",
      text: "Emily's emotional journey highlights the [urgent need] for a natural, effective gum that not only freshens breath, but also strengthens teeth and prevents decay. Something that aligns her health goals with her daily habits.",
    },
  },
  transition_two: {
    title: "Tracing the Journey",
    text: "Now that we understand the motivations and goals of potential customers, let's explore how market drivers like health consciousness, convenience, and innovation have propelled the [$38 billion] functional gum industry.",
  },
  history_title: { small: "A Brief Look At", large: "Gum History" },
  history: {
    one: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/26047997f83735fda091f47f38968b21b7a491bef71fd9358c820a881aa61ad1",
      date: "1899",
      title: 'Birth of "Tooth-Friendly" Gum',
      text: "Remineralizing gum traces its roots to 1899 with the launch of [Dentyne, promoted as a 'tooth-friendly' gum]. While it wasn't specifically formulated for remineralization, it set the stage for future advancements health-focused chewing gum.",
    },
    two: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/80fb1a1ef2990ddafe97b540b46492e8b403716692cc68a9df4f3c5ce7363a07",
      date: "1980s",
      title: "Scientific Breakthroughs",
      text: "In the 1980s, Dr. Reynolds in Australia patented Recaldent, a milk-derived ingredient rich in calcium for [enamel repair]. Recognizing its potential, Pfizer conducted studies in the 90s to integrate Recaldent into news gums.",
    },
    three: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/5a35e6dd85e09c7b1ff6e971fe49aa5956dbff4e96d65a44b6e2850a04db7eb2",
      date: "2000s",
      title: "Remineralizing Hits the Market",
      text: "By the early 2000s, Pfizer introduced products like Trident Xtra Care and Trident White. These products [gained traction due to growing dental health awareness and scientific validation]. People started to see gums as something to add to their oral hygiene practices.",
    },
    four: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/01e46075970ec0ac5fa4ad43eac5f0be38dcb359e1bc79f8dc8f84fd596bc3ae",
      date: "2024+",
      title: "Rise of Advanced Remineralizing Gum",
      text: "Building on earlier breakthroughs, modern remineralizing gum leverages advanced ingredients like hydroxyapatite for [enamel repair while stimulating saliva flow and controlling bacteria]. Thanks to ongoing research, these gums now offer multi-functional benefits such as natural whitening.",
    },
  },
  present_title: { small: "How Things", large: "Look Today" },
  present: {
    search: {
      graph: "https://d2oa23hr7q6t96.cloudfront.net/report/present1.png",
      big_num: "4.4k",
      subscript: "Monthly Searches",
      percent: "+4100%",
      text: "Remineralizing gum is experiencing explosive growth in online interest, with search volumes increasing by [4100% over the past two years] and monthly searches currently reaching [4.4k]. This momentum is particularly strong on TikTok, where top videos surpass [100k views], and forecasts suggest continued global expansion in the coming year.",
      sources: "Google Trends & SEMRush",
    },
    social: {
      convos: [
        {
          quote:
            "I really like it...your mouth feels good after eating and chewing this gum",
          label: "User Experience",
          source: "Instagram",
        },
        {
          quote: "My teeth are also a little whiter",
          label: "Product Benefits",
          source: "Facebook",
        },
        {
          quote: "I would absolutely not pay $9 for a pack",
          label: "Price Sensitivity",
          source: "Reddit",
        },
      ],
      text: "Social conversations show a balance of skepticism about the price and doubts about proven effectiveness, contrasted with positive anecdotes of improved dental health and whitening effects.",
      sources: "Social Media Monitoring",
    },
    meta_trend: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/bb30922145a3d9a04bbd08895fee14f63fe5986767b818c084268f6994c21505",
      grouping: "Natural Functional Oral Care",
      analysis: {
        core: "Natural, functional oral care products moving beyond simple freshening to actively support tooth health.",
        keys: [
          "Non-toxic, biocompatible ingredients",
          "Preventative dental solutions",
          "Eco-friendly formulations",
        ],
        goal: "Delivering comprehensive oral wellness through natural solutions.",
      },
      sources: "",
    },
  },
  case_title: { small: "Time For A Quick", large: "Case Study" },
  case: {
    company_name: "Underbrush",
    company_logo:
      "https://d2oa23hr7q6t96.cloudfront.net/report/underbrushlogo.jpg",
    mission: {
      title: "Chew Without Fear",
      text: "They end big-brand gum's mystery with real ingredients, creating a healthier chew for all.",
    },
    operations: {
      title: "Science + Sap",
      text: "Their gum blends nano-hydroxyapatite with real tree saps, pioneering advanced biomimetic and eco-friendly oral care.",
    },
    marketing: {
      title: "Handmade Goes Viral",
      text: "Viral TikToks showcase hand-crafted gum and ingredient breakdowns, driving massive engagement and consumer trust.",
    },
    customers: {
      title: "150k+",
      text: "Monthly traffic tops 150k, with a majority of visitors aged 25-44, and 75% hailing from the U.S.",
    },
    revenue: {
      title: "$2M+",
      text: "They gross over $2M [monthly], confirming their premium gum's place in the big leagues",
    },
    sources: "SimilarWeb, Google Analytics, SEMRush, Social Media Monitoring",
  },
  meaning_title: { small: "What Does This Mean", large: "For You?" },
  ideas: {
    1: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/c3737bce3a72bfcf0e9f09e4147fa50538beb34092883556bce108acead5f4bb",
      name: "Gum Comparison and Review Platform",
      what_it_is:
        "A platform to compare and review remineralizing gums, empowering consumers with personalized insights for holistic and oral health choices.",
      one_liner:
        "Chew wisely: Your ultimate hub for comparing and discovering the best remineralizing gums.",
      reasons: [
        "Centralizes trusted reviews and detailed ingredient breakdowns in one place.",
        "Simplifies decision-making for overwhelmed consumers",
        "Builds trust by featuring user testimonials and expert insights.",
        "Appeals to research-driven buyers who value informed purchasing decisions.",
        "Offers convenience by streamlining the search for the right gum.",
      ],
      niches: [
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/6c89ab198b3e64afccf8da67141e89ca96c1b31efa0e2bb6a6121ca3325a6f3f",
          name: "Parents Looking for Child-Friendly Options",
          description:
            "Families interested in safe and effective remineralizing gums suitable for children.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/5f519ffb03869d4c43cbb409730ca5f7627d8b2de3ab66a1e35cc4b1d778fd6c",
          name: "Vegan and Cruelty-Free Enthusiasts",
          description:
            "Consumers seeking gums that are free from animal products and not tested on animals.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/d8ccb66ec5edc903c434fa70dc346d793c452ce4b09f525a6a5acd22cd0eb724",
          name: "Individuals with Dietary Restrictions",
          description:
            "People looking for sugar-free, gluten-free, or diabetic-friendly remineralizing gums.",
        },
      ],
      launch_plan_label: "your review platform", //test [] over the weekend.
    },
    2: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/857165cea3ab12d0e92013422c75be3e7f3f31395cb4362289e2fd2cc8517430",
      name: "Holistic Gum Buyer's Guide",
      what_it_is:
        "A downloadable guide that helps consumers compare and choose the best gums for holistic health and oral care.",
      one_liner:
        "Cut through the gum aisle clutter with our holistic, one-stop guide to the best remineralizing chews on the market.",
      reasons: [
        "Provides a structured, comprehensive overview in one resource.",
        "Offers offline access for users to reference anytime",
        "Quick to create and distribute with minimal technical setup.",
        "Ideal for targeting consumers seeking immediate, actionable insights.",
        "Perceived as a polished, premium product",
        "Allows bundling with other products or guides for added value.",
      ],
      niches: [
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/d8ccb66ec5edc903c434fa70dc346d793c452ce4b09f525a6a5acd22cd0eb724",
          name: "Ingredient Purists",
          description:
            "Individuals who scrutinize labels for harmful chemicals and want exclusively natural, clean ingredients.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/7083061f07665ea6505b7ea28450e7d618c37b751c7f6c7440867dee3ec99741",
          name: "Budget Hunters",
          description:
            "Those who want the best value for their money without compromising on efficacy.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/f3fcc9bb10f40b6626db8750e4150f4db8653e1ea763950b5c439cac01a9608c",
          name: "Health Professionals",
          description:
            "Dental hygienists, naturopaths, and holistic wellness coaches who want to recommend the best products to clients.",
        },
      ],
      launch_plan_label: "your buyer's guide",
    },
    3: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/41b5f7daec9897a2f816fe2cfa458a3c7be15490fdfbb2fa960724ebe1c6d21c",
      name: "A dedicated Regulatory & Compliance Advisory Service",
      what_it_is:
        "A consulting service helping remineralizing gum brands navigate regulatory compliance, ensure safety, and confidently make health claims.",
      one_liner:
        "Navigate the legal maze of remineralizing gum compliance—effortlessly and risk-free.",
      reasons: [
        "Simplifies navigating complex and evolving regulatory requirements.",
        "Helps brands confidently make health and functional claims.",
        "Prevents costly mistakes and potential legal liabilities.",
        "Supports entry into new international markets with varying regulations.",
        "Protects premium brands' credibility",
      ],
      niches: [
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/b9b317b132cfd151b81dc18af1bc6548c0778db8054f3bc6459fc1500a83e2b9",
          name: "Organic/Natural Brands",
          description:
            "Companies using all-organic ingredients that must meet specific certification standards.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/d64e9dbcf8aed9e05b11b0bd582d4d3aa02b823072f5f1a18891202d71017668",
          name: "Innovative Ingredient Startups",
          description:
            "Newcomers leveraging cutting-edge remineralizing agents (e.g., nano-hydroxyapatite) who need guidance navigating uncharted regulatory territory.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/2630c5a9d512a37cc48fad97c256601d507e889380ec6b8a4b3539420f456004",
          name: "International Expansion Brands",
          description:
            "Established remineralizing gum brands seeking to launch in new countries and navigate different health claim regulations",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/920a0b855bb09302bb6bb7a1d9d321fa9bd8f5b77e2bf2a3ebbac698421f95d3",
          name: "High-Luxury/Premium Gums",
          description:
            "Premium brands focused on advanced functional claims (e.g., remineralization plus whitening) requiring strict compliance to uphold premium positioning",
        },
      ],
      launch_plan_label: "your advisory service",
    },
    4: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/57fa34c65747cc296a9b5f7b56b609fed86c04c1bd8e0209ddde2794d9a26773",
      name: "Subscription-based, clean-label gum",
      what_it_is:
        "A subscription service delivering natural, clean-label gum monthly",
      one_liner:
        "Meet your monthly fix of pure, tooth-loving gum—100% natural, zero nonsense.",
      reasons: [
        "Provides consistent supply of natural, health-focused gum products.",
        "Offers convenience for busy, health-conscious individuals.",
        "Creates recurring revenue, ensuring predictable business income streams.",
        "Lets people try new gums with no hassle.",
      ],
      niches: [
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/f3fcc9bb10f40b6626db8750e4150f4db8653e1ea763950b5c439cac01a9608c",
          name: "Dental Professionals & Hygienists",
          description:
            "Practitioners who might recommend functional gum as part of a daily oral care routine.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/558aadbbe53e86d4535fb1f98793beab682b5f73c2e17f4cc3122b2996965872",
          name: "Sensitive Teeth Sufferers",
          description:
            "Individuals seeking gentler, remineralizing products that address enamel erosion and gum sensitivity.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/3bcf18ab33e3ab6aece2651858058018ebecaf4f41d0110e79afc2cc4069cedf",
          name: "Busy, Health-Focused Urban Millennials",
          description:
            "City-dwellers juggling demanding jobs and wellness goals who want convenient oral health solutions.",
        },
      ],
      launch_plan_label: "your subscription gum service",
    },
    5: {
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/97aa00e1b171c9f8df91acb1c16f7db4611fd91bf7235cfaf9430760803225c0",
      name: "Guided 30-day challenge",
      what_it_is:
        "A 30-day challenge that helps people use remineralizing gum to improve their oral health with guidance and support.",
      one_liner:
        "Join the 30-Day Remineralizing Challenge and chew your way to a healthier, brighter smile!",
      reasons: [
        "Creates a sense of community through shared participation.",
        "Offers measurable progress, appealing to results-driven individuals.",
        "Builds a loyal customer base for future product upsells.",
        "Premium pricing for guided, educational experiences.",
        "Can Partner with a company - increases trust and loyalty for the brand offering the challenge.",
      ],
      niches: [
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/5f519ffb03869d4c43cbb409730ca5f7627d8b2de3ab66a1e35cc4b1d778fd6c",
          name: "Vegan Remineralizers",
          description:
            "Cater to those specifically seeking plant-based or cruelty-free gum options and holistic oral care steps.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/7083061f07665ea6505b7ea28450e7d618c37b751c7f6c7440867dee3ec99741",
          name: "Budget-Conscious Shoppers",
          description:
            "Focus on showing how daily routines and the right gum can potentially reduce expensive dental work down the line.",
        },
        {
          image:
            "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/04de7f7855a6304b214d5d3dc3147480929f20a822dc71c1fb573246a677a5b0",
          name: "Biohackers and Wellness Pros",
          description:
            "Provide in-depth data tracking and more scientific insights, appealing to those interested in optimization and measurable health gains.",
        },
      ],
      launch_plan_label: "your 30-day challenge",
    },
  },
  transition_three: {
    title: "We Hope You Found This Insightful!",
    text: "This report is just a glimpse of our weekly business trend insights, [tailored specifically for each person]!",
  },
};
