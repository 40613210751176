import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import "../assets/styling/hero.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useSignupMutation } from "../slices/usersApiSlice";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { useLocation } from "react-router-dom";
// import { landing } from "../utils/rotation";
import { landing } from "../utils/adrotations";
import ReCAPTCHA from "react-google-recaptcha";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const SubHero = () => {
  const recaptchaRef = useRef();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [signup, { isLoading }] = useSignupMutation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [page_content, setPage] = useState({
    title: "",
    span: "",
    title2: "",
    p1: "",
    strong: "",
    p2: "",
    pic: "",
  });

  useEffect(() => {
    const param1 = searchParams.get("camp");
    if (param1) {
      setPage(landing(param1));
    } else {
      setPage(landing("v1"));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") return;
    try {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      const res = await signup({ email, captchaToken }).unwrap();

      // Save access token to localStorage if signup successful
      if (res.access_token) {
        localStorage.setItem("accessToken", res.access_token);
      }

      fbq("track", "Subscribe");

      //sparkloop to handle redirect
      navigate("/find-your-business-fit?start=1");
    } catch (error) {
      console.log(error);
      console.log("error");
      setErr(true);
    }
  };
  const err_msg = (
    <span className="err-msg">Error... please try again later</span>
  );
  return (
    <div style={{ marginTop: "1em" }}>
      <div className="hero-left">
        <div>
          <p className="hero-title">
            {page_content.title}
            <span>{page_content.span}</span>
            {page_content.title2}
          </p>
          <p className="quiz-home-subhead">
            {page_content.p1} <strong>{page_content.strong}</strong>
            {page_content.p2}
          </p>
          <p className="quiz-home-subhead" style={{ marginBottom: "0" }}>
            <span className="join-free-text">Join for free!</span>
          </p>
          {isLoading ? (
            <Loader h={"12em"} m={"0"} w={"300px"} />
          ) : (
            <form onSubmit={handleSubmit}>
              <div>
                {err ? err_msg : null}
                <TextField
                  className="hero-input"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="filled"
                  id="filled-basic"
                  label="Your Email Address"
                />
                <Button className="hero-sub-landing" type="submit">
                  Subscribe Free <ArrowForwardIcon />
                </Button>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={"6LcUkYkoAAAAADH7KJoPEM1bYaEqkipI8wBlPCLU"}
                  size="invisible"
                />
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="hero-right">
        <div>
          <img className="hero-img sublanding-hero" src={page_content.pic} />
        </div>
      </div>
    </div>
  );
};

export default SubHero;
