import React from "react";
import "../../assets/styling/footer.css";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";

const FooterSignUp = () => {
  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="footer-signup-container">
      <p onClick={scrollToTop} className="footer-signup-text">
        Subscribe for{" "}
        <a href="#top" className="footer-signup-link">
          free <HowToRegRoundedIcon sx={{ fontSize: "34px" }} />
        </a>
      </p>
    </div>
  );
};

export default FooterSignUp;
