import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";

const TakeQuizModal = ({ show }) => {
  const navigate = useNavigate();

  return (
    <Modal show={show} centered className="biz-report-modal">
      <Modal.Header
        style={{
          border: "0",
          padding: "2rem 2rem 1rem",
        }}
      >
        <Modal.Title
          style={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "24px",
            color: "#212529",
          }}
        >
          <ReportProblemRoundedIcon
            sx={{ color: "#d49060", fontSize: "34px" }}
          />{" "}
          We don't know what your skills are...
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0 2rem 2rem" }}>
        <p
          style={{
            fontFamily: "Nunito",
            fontSize: "16px",
            color: "#495057",
            marginBottom: "1.5rem",
          }}
        >
          Please take the survey first to so we can personalize your business
          report.
        </p>
        <Button
          className="hd2-sub"
          style={{
            margin: "0",
            display: "block",
            width: "100%",
            background: "#6082D4",
            border: "none",
            padding: "0.8rem",
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "18px",
            textTransform: "uppercase",
            transition: "background-color 0.2s ease",
            boxShadow: "0 4px 12px rgba(96, 130, 212, 0.2)",
          }}
          onClick={() => navigate("/find-your-business-fit?start=1")}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#4C6BAF")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#6082D4")}
        >
          Take the Survey
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default TakeQuizModal;
